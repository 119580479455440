import React, { useState } from "react"
import cmptStyles from "../../styles/modules/feedArticles.module.css"
import { useStaticQuery, graphql } from "gatsby"
import { ContentfulImage } from "../shared/image"
import { AutoLink } from "../shared/link"
import Filter from "../shared/filter"

const defaultFilter = 'viewAll';

const Article = ({ 
    author,
    category,
    image,
    publishedDate,
    route,
    summary: {
        summary
    },
    title,
    authoredByPrefix,
    activeFilter
}) => {

    const showArticle = ( ( activeFilter === category.toLowerCase() ) || ( activeFilter === defaultFilter ) ) ? true : false;

    return (
        <>
        {
            showArticle && 
            <li className={ cmptStyles.article }>
                <AutoLink uri={ route }>
                    <div className={ cmptStyles.articleImage }>
                        <figure>
                            <ContentfulImage image={ image } />
                        </figure>
                    </div>
                    <div className={ cmptStyles.articleText }>
                        <span className={ cmptStyles.articleCategory }>{ category }</span>
                        <span className={ cmptStyles.articleTitle }>{ title }</span>
                        <span className={ cmptStyles.articleSummary }>{ summary }</span>
                        <span className={ cmptStyles.articleAuthoredBy }>
                            {
                                author &&
                                <>
                                    { authoredByPrefix } { author.name } { '| ' }
                                </>
                            }
                            { publishedDate }
                        </span>
                    </div>
                </AutoLink>
            </li>
        }
        </>
    )
}

const FeedArticles = ({ 
    siteFurniture: { 
        authoredByPrefix
    }
}) => {

    const [ activeFilter, setActiveFilter ] = useState(defaultFilter);
    const toggleFilter = (filter) => {
        setActiveFilter(filter.filter);
    }

    const data = useStaticQuery(
        graphql`
            query {
                articles: allContentfulPageArticle( sort: { fields: publishedDate, order: DESC}, filter: { node_locale: { eq: "en-US" }, nickname: { ne: "Sample - DO NOT USE OR DELETE" } } ) {
                    edges {
                        node {
                            ...FeedArticle
                        }
                    }
                }
            }
        `
    )

    return (
        <section className={ cmptStyles.wrapper } id="feedArticles">
            <div className={ cmptStyles.container }>

                <Filter activeGroup="articles" activeFilter={ activeFilter } toggleFilter={ (filter) => toggleFilter({ filter }) }/>

                <div className={ cmptStyles.inner }>

                    <ul className={ cmptStyles.list }>
                        { data.articles.edges.map( article => (
                            <Article
                                { ...article.node }
                                authoredByPrefix={ authoredByPrefix }
                                activeFilter={ activeFilter }
                            />
                        )) }
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default FeedArticles

export const feedArticleQuery = graphql`
    fragment FeedArticle on ContentfulPageArticle {
        id
        node_locale
        author {
            name
        }
        category
        image {
            ...ContentfulImageProperties
        }
        publishedDate(formatString: "ddd MMM DD YYYY")
        route
        summary {
            summary
        }
        title
    }
`