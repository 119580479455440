import React from "react"
import cmptStyles from "../../styles/modules/textOneColumn.module.css"
import { graphql } from "gatsby"
import RichText from "../shared/richtext"

const TextOneColumn = ({ 
    content: { 
        fullText 
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="oneColumn">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <div className={ cmptStyles.text }>
                        <RichText content={ fullText }/>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default TextOneColumn

export const textOneColumnQuery = graphql`
    fragment TextOneColumn on ContentfulModuleTextOneColumn {
        id
        internal {
            type
        }
        fullText {
            json
        }
    }
`