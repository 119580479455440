import React from "react"
import cmptStyles from "../../styles/modules/feed.module.css"
import { graphql } from "gatsby"
import FeedArticles from "./feedArticles"

const Feed = ({ 
    content: { 
        typeOfFeed
    },
    siteFurniture
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="feed">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                {
                    (typeOfFeed === 'Articles') &&
                    <FeedArticles siteFurniture={ siteFurniture }/>
                }

                </div>
            </div>
        </section>
    )
}

export default Feed

export const feedQuery = graphql`
    fragment Feed on ContentfulModuleFeed {
        id
        internal {
            type
        }
        typeOfFeed
    }
`