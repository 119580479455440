import React from "react"
import cmptStyles from "../../styles/modules/heroOverlay.module.css"
import { graphql } from "gatsby"
import { AndroidDownloadButton, AppDownloadButton, OutlineButton } from "../shared/button"
import { ContentfulImage } from "../shared/image"

const HeroOverlay = ({ 
    content: { 
        customCallToActionExternalLink,
        customCallToActionLabel, 
        customCallToActionTargetPage, 
        description: {
            description
        }, 
        includeAppDownloadCallToAction, 
        primaryImage, 
        secondaryImage, 
        title
    },
    siteFurniture: { 
        iOsAppStoreDownload,
        aOsAppStoreDownload
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="overlay">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <div className={ cmptStyles.text }>
                        <h1 className={ cmptStyles.title }>{ title }</h1>
                        <p className={ cmptStyles.description }>{ description }</p>
                        
                        {
                            ( includeAppDownloadCallToAction || customCallToActionExternalLink || customCallToActionTargetPage ) &&
                            <ul className={ cmptStyles.links }>
                                {
                                    includeAppDownloadCallToAction &&
                                    <li>
                                        <AppDownloadButton
                                            content={ iOsAppStoreDownload }
                                        />
                                        <AndroidDownloadButton
                                            content={ aOsAppStoreDownload }
                                        />
                                    </li>
                                }
                                {
                                    customCallToActionExternalLink && 
                                    <li>
                                        <OutlineButton
                                            route={ customCallToActionExternalLink }
                                            label={ customCallToActionLabel }
                                        />
                                    </li>
                                }
                                {
                                    !customCallToActionExternalLink && customCallToActionTargetPage &&
                                    <li>
                                        <OutlineButton
                                            route={ customCallToActionTargetPage.route }
                                            label={ customCallToActionLabel }
                                        />
                                    </li>
                                }
                            </ul>
                        }
                    </div>

                    <div className={ cmptStyles.primaryImage }>
                        <figure>
                            <ContentfulImage image={ primaryImage } />
                        </figure>
                    </div>

                    <div className={ cmptStyles.secondaryImage }>
                        <figure>
                            <ContentfulImage image={ secondaryImage } />
                        </figure>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default HeroOverlay

export const heroOverlayQuery = graphql`
    fragment HeroOverlay on ContentfulModuleHeroOverlay {
        id
        internal {
            type
        }
        customCallToActionExternalLink
        customCallToActionLabel
        customCallToActionTargetPage {
            route
        }
        description {
            description
        }
        includeAppDownloadCallToAction
        primaryImage {
            ...ContentfulImageProperties
        }
        secondaryImage {
            ...ContentfulImageProperties
        }
        title
    }
`