import React from "react"
import cmptStyles from "../../styles/modules/teaserApp.module.css"
import { graphql } from "gatsby"
import { AndroidDownloadButton, AppDownloadButton } from "../shared/button"

const TeaserApp = ({ 
    content: { 
        displayXptLogo,
        message
    }, 
    siteFurniture: { 
        iOsAppStoreDownload,
        aOsAppStoreDownload,
        logoWithoutText
    }
 }) => {
    return (
        <section className={ cmptStyles.wrapper } id="app">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    {
                        displayXptLogo &&
                        <div
                            className={ cmptStyles.icon }
                            style={{
                                backgroundImage: `url(${ logoWithoutText.file })`,
                            }}
                        />
                    }
                    <p className={ cmptStyles.message }>{ message }</p>
                    <div className={ cmptStyles.download }>
                        <AppDownloadButton
                            content={ iOsAppStoreDownload }
                        />
                        <AndroidDownloadButton
                            content={ aOsAppStoreDownload }
                        />
                    </div>

                </div>
            </div>
        </section>
    )
}

export default TeaserApp

export const teaserAppQuery = graphql`
    fragment TeaserApp on ContentfulModuleTeaserApp {
        id
        internal {
            type
        }
        displayXptLogo
        message
    }
`