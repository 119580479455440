import React, { useState } from "react"
import cmptStyles from "../../styles/modules/team.module.css"
import { graphql } from "gatsby"
import { ContentfulImage } from "../shared/image"
import Filter from "../shared/filter"
import RichText from "../shared/richtext"

const defaultFilter = 'founders';

const Founders = ({ 
    members
}) => (
    members.map( founder => (
        <Founder { ...founder } key={ founder.name }/>
    ))
)

const Founder = ({
    bio, 
    image,
    name, 
    role
}) => (
    <li className={ cmptStyles.founder }>
        {
            image &&
            <div className={ cmptStyles.itemImage }>
                <figure>
                    <ContentfulImage image={ image } />
                </figure>
            </div>
        }
        <div className={ cmptStyles.itemText }>
            <span className={ cmptStyles.itemName }>{ name }</span>
            {
                role &&
                <span className={ cmptStyles.itemRole }>{ role }</span>
            }
            {
                bio &&
                <div className={ cmptStyles.itemBio }>
                    <RichText content={ bio } />
                </div>
            }
        </div>
    </li>
)

const TeamMembers = ({ 
    members
}) => (
    members.map( teamMember => (
        <TeamMember { ...teamMember } key={ teamMember.name }/>
    ))
)

const TeamMember = ({ 
    bio,
    name, 
    role 
}) => (
    <li className={ cmptStyles.teamMember }>
        <div className={ cmptStyles.itemText }>
            <span className={ cmptStyles.itemName }>{ name }</span>
            {
                role &&
                <span className={ cmptStyles.itemRole }>{ role }</span>
            }
            {
                bio &&
                <div className={ cmptStyles.itemBio }>
                    <RichText content={ bio } />
                </div>
            }
        </div>
    </li>
)

const Advisors = ({ 
    members
}) => (
    members.map( advisor => (
        <Advisor { ...advisor } key={ advisor.name }/>
    ))
)

const Advisor = ({ 
    bio,
    name, 
    role 
}) => (
    <li className={ cmptStyles.advisor }>
        <div className={ cmptStyles.itemText }>
            <span className={ cmptStyles.itemName }>{ name }</span>
            {
                role &&
                <span className={ cmptStyles.itemRole }>{ role }</span>
            }
            {
                bio &&
                <div className={ cmptStyles.itemBio }>
                    <RichText content={ bio } />
                </div>
            }
        </div>
    </li>
)

const Ambassadors = ({ 
    members
}) => (
    members.map( ambassador => (
        <Ambassador { ...ambassador } key={ ambassador.name }/>
    ))
)

const Ambassador = ({ 
    image, 
    name, 
    role
}) => (
    <li className={ cmptStyles.ambassador }>
        {
            image &&
            <div className={ cmptStyles.itemImage }>
                <figure>
                    <ContentfulImage image={ image } />
                </figure>
            </div>
        }
        <div className={ cmptStyles.itemText }>
            <span className={ cmptStyles.itemName }>{ name }</span>
            {
                role &&
                <span className={ cmptStyles.itemRole }>{ role }</span>
            }
        </div>
    </li>
)

const Team = ({ 
    content: { 
        advisors, 
        ambassadors, 
        founders, 
        team
    }
}) => {

    const [ activeFilter, setActiveFilter ] = useState(defaultFilter);
    const toggleFilter = (filter) => {
        setActiveFilter(filter.filter);
    }

    const showFounders = ( activeFilter === 'founders' ) ? true : false;
    const showTeamMembers = ( activeFilter === 'team' ) ? true : false;
    const showAdvisors = ( activeFilter === 'advisors' ) ? true : false;
    const showAmbassadors = ( activeFilter === 'ambassadors' ) ? true : false;

    return (
        <section className={ cmptStyles.wrapper } id="team">
            <div className={ cmptStyles.container }>

                <Filter activeGroup="team" activeFilter={ activeFilter } toggleFilter={ (filter) => toggleFilter({filter}) }/>

                <div className={ cmptStyles.inner }>

                    <ul className={ cmptStyles.members }>
                        {
                            showFounders &&
                            <Founders members={ founders }/>
                        }
                        {
                            showTeamMembers &&
                            <TeamMembers members={ team }/>
                        }
                        {
                            showAdvisors &&
                            <Advisors members={ advisors }/>
                        }
                        {
                            showAmbassadors &&
                            <Ambassadors members={ ambassadors }/>
                        }
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default Team

export const teamQuery = graphql`
    fragment Team on ContentfulModuleTeam {
        id
        internal {
            type
        }
        advisors {
            bio {
                json
            }
            name
            role
        }
        ambassadors {
            image {
                ...ContentfulImageProperties
            }
            name
            role
        }
        founders {
            bio {
                json
            }
            image {
                ...ContentfulImageProperties
            }
            name
            role
        }
        team {
            bio {
                json
            }
            name
            role
        }
    }
`