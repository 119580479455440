import React from "react"
import cmptStyles from "../../styles/modules/featuredFourUp.module.css"
import { graphql } from "gatsby"
import { ContentfulImage } from "../shared/image"
import { AutoLink } from "../shared/link"

const OneUp = ({ 
    image, 
    label, 
    route, 
    textLine1, 
    textLine2
}) => (
    <li className={ cmptStyles.item }>
        <AutoLink uri={ route }>
            <div className={ cmptStyles.itemImage }>
                <figure>
                    <ContentfulImage image={ image } />
                </figure>
            </div>
            <div className={ cmptStyles.itemContent }>
                <span className={ cmptStyles.itemText }>
                    { textLine1 }<br/>
                    { textLine2 }
                </span>
                <span className={ cmptStyles.itemLabel }>
                    { label }
                </span>
            </div>
        </AutoLink>
    </li>
)

const FeaturedFourUp = ({ 
    content: { 
        section1Image, 
        section1TargetPage, 
        section1TextLine1, 
        section1TextLine2, 
        section2Image, 
        section2TargetPage, 
        section2TextLine1, 
        section2TextLine2, 
        section3Image, 
        section3TargetPage, 
        section3TextLine1, 
        section3TextLine2, 
        section4Image, 
        section4TargetPage, 
        section4TextLine1, 
        section4TextLine2, 
        title
    }, 
    siteFurniture: { 
        fourUpLabelText
    } 
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="fourUp">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <h2 className={ cmptStyles.title }>{ title }</h2>
                    <div className={ cmptStyles.items }>
                        <ul>
                            <OneUp
                                textLine1={ section1TextLine1 }
                                textLine2={ section1TextLine2 }
                                image={ section1Image }
                                label={ fourUpLabelText }
                                route={ section1TargetPage.route }
                            />
                            {
                                section2TextLine1 &&
                                <OneUp
                                    textLine1={ section2TextLine1 }
                                    textLine2={ section2TextLine2 }
                                    image={ section2Image }
                                    label={ fourUpLabelText }
                                    route={ section2TargetPage.route }
                                />
                            }
                            {
                                section3TextLine1 &&
                                <OneUp
                                    textLine1={ section3TextLine1 }
                                    textLine2={ section3TextLine2 }
                                    image={ section3Image }
                                    label={ fourUpLabelText }
                                    route={ section3TargetPage.route }
                                />
                            }
                            {
                                section4TextLine1 &&
                                <OneUp
                                    textLine1={ section4TextLine1 }
                                    textLine2={ section4TextLine2 }
                                    image={ section4Image }
                                    label={ fourUpLabelText }
                                    route={ section4TargetPage.route }
                                />
                            }
                        </ul>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default FeaturedFourUp

export const featuredFourUpQuery = graphql`
    fragment FeaturedFourUp on ContentfulModuleFeaturedFourUp {
        id
        internal {
            type
        }
        section1Image {
            ...ContentfulImageProperties
        }
        section1TargetPage {
            ... on ContentfulPageArticle{
                route
            } 
            ... on ContentfulPageModular{
                route
            }
        }
        section1TextLine1
        section1TextLine2
        section2Image {
            ...ContentfulImageProperties
        }
        section2TargetPage {
            ... on ContentfulPageArticle{
                route
            } 
            ... on ContentfulPageModular{
                route
            }
        }
        section2TextLine1
        section2TextLine2
        section3Image {
            ...ContentfulImageProperties
        }
        section3TargetPage {
            ... on ContentfulPageArticle{
                route
            } 
            ... on ContentfulPageModular{
                route
            }
        }
        section3TextLine1
        section3TextLine2
        section4Image {
            ...ContentfulImageProperties
        }
        section4TargetPage {
            ... on ContentfulPageArticle{
                route
            } 
            ... on ContentfulPageModular{
                route
            } 
        }
        section4TextLine1
        section4TextLine2
        title
    }
`