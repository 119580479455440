import React from "react"
import cmptStyles from "../../styles/modules/insight.module.css"
import { graphql } from "gatsby"
import { FilledButton } from "../shared/button"
import { ContentfulImage } from "../shared/image"

const Insight = ({ 
    content: { 
        callToActionExternalLink,
        callToActionTargetPage,
        description: {
            description
        }, 
        image, 
        imagePlacement, 
        title
    }, 
    siteFurniture: { 
        insightMoreText
    }
}) => {

    const cmptImagePlacement = ( imagePlacement === 'Left' ) ? cmptStyles.leftAlignment : cmptStyles.rightAlignment;

    return (
        <section className={ cmptStyles.wrapper } id="insight">
            <div className={ [ cmptStyles.container, cmptImagePlacement ].join(' ') }>
                <div className={ cmptStyles.inner }>
                    
                    <div className={ cmptStyles.image }>
                        <figure>
                            <ContentfulImage image={ image } />
                        </figure>
                    </div>
                
                    <div className={ cmptStyles.text }>
                        <h2 className={ cmptStyles.title }>{ title }</h2>
                        <p className={ cmptStyles.description }>{ description }</p>
                
                        {
                            ( callToActionExternalLink || callToActionTargetPage ) &&
                            <ul className={ cmptStyles.links }>
                            {
                                callToActionExternalLink && 
                                <li className={ cmptStyles.callToAction }>
                                    <FilledButton
                                        route={ callToActionExternalLink }
                                        label={ insightMoreText }
                                    />
                                </li>
                            }
                            {
                                !callToActionExternalLink && callToActionTargetPage &&
                                <li className={ cmptStyles.callToAction }>
                                    <FilledButton
                                        route={ callToActionTargetPage.route }
                                        label={ insightMoreText }
                                    />
                                </li>
                            }
                            </ul>
                        }
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Insight

export const insightQuery = graphql`
    fragment Insight on ContentfulModuleInsight {
        id
        internal {
            type
        }
        callToActionExternalLink
        callToActionTargetPage {
            route
        }
        description {
            description
        }
        image {
            ...ContentfulImageProperties
        }
        imagePlacement
        title
    }
`