import React from "react"
import cmptStyles from "../../styles/modules/introduction.module.css"
import { graphql } from "gatsby"

const Introduction = ({ 
    content: { 
        summary,
        text,
        title
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="introduction">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>

                    {
                        title &&
                        <h2 className={ cmptStyles.title }>{ title }</h2>
                    }
                    {
                        summary &&
                        <p className={ cmptStyles.summary }>{ summary.summary }</p>
                    }
                    {
                        text &&
                        <p className={ cmptStyles.text }>{ text.text }</p>
                    }

                </div>
            </div>
        </section>
    )
}

export default Introduction

export const introductionQuery = graphql`
    fragment Introduction on ContentfulModuleIntroduction {
        id
        internal {
            type
        }
        summary {
            summary
        }
        text {
            text
        }
        title
    }
`