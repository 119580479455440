import React from "react"
import cmptStyles from "../../styles/modules/comparison.module.css"
import { graphql } from "gatsby"
import { ContentfulImage } from "../shared/image"

const Item = ({ 
    title, 
    description, 
    image, 
    items
}) => (
    <div className={ cmptStyles.item }>
        <div className={ cmptStyles.itemImage }>
            <figure>
                <ContentfulImage image={ image } />
            </figure>
        </div>
        <div className={ cmptStyles.itemContent }>
            <h3 className={ cmptStyles.itemTitle }>{ title }</h3>
            <p className={ cmptStyles.itemDescription }>{ description }</p>
            <ul className={ cmptStyles.itemItems }>
                { items.map( item => (
                    <li key={ item } className={ cmptStyles.itemItem }>{ item }</li>
                )) }
            </ul>
        </div>
    </div>
)

const Comparison = ({ 
    content: { 
        title,
        comparison1Title, 
        comparison1Description: {
            comparison1Description
        }, 
        comparison1Image, 
        comparison1ListItems, 
        comparison2Title, 
        comparison2Description: {
            comparison2Description
        }, 
        comparison2Image, 
        comparison2ListItems
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="comparison">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <h2 className={ cmptStyles.title }>{ title }</h2>
                    <div className={ cmptStyles.scroll }>
                        <div className={ cmptStyles.items }>
                            <Item
                                title={ comparison1Title }
                                description={ comparison1Description }
                                image={ comparison1Image }
                                items={ comparison1ListItems }
                            />
                            {
                                comparison2Title &&
                                <Item
                                    title={ comparison2Title }
                                    description={ comparison2Description }
                                    image={ comparison2Image }
                                    items={ comparison2ListItems }
                                />
                            }
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default Comparison

export const comparisonQuery = graphql`
    fragment Comparison on ContentfulModuleComparison {
        id
        internal {
            type
        }
        title
        comparison1Title
        comparison1Description {
            comparison1Description
        }
        comparison1Image {
            ...ContentfulImageProperties
        }
        comparison1ListItems
        comparison2Title
        comparison2Description {
            comparison2Description
        }
        comparison2Image {
            ...ContentfulImageProperties
        }
        comparison2ListItems
    }
`