import React from "react"
import cmptStyles from "../../styles/modules/feature.module.css"
import { graphql } from "gatsby"
import { AndroidDownloadButton, AppDownloadButton } from "../shared/button"
import { ContentfulImage } from "../shared/image"
import { AutoLink } from "../shared/link"

const Feature = ({ 
    content: { 
        callToActionExternalLink,
        callToActionLabel, 
        callToActionTargetPage,
        description: {
            description
        },
        image, 
        includeAppDownloadCallToAction,
        title
    },
    siteFurniture: { 
        iOsAppStoreDownload,
        aOsAppStoreDownload
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="feature">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <div className={ cmptStyles.text }>
                        <h2 className={ cmptStyles.title }>{ title }</h2>
                        <p className={ cmptStyles.description }>{ description }</p>
                
                        {
                            ( includeAppDownloadCallToAction || callToActionExternalLink || callToActionTargetPage ) &&
                            <ul className={ cmptStyles.links }>
                            {
                                includeAppDownloadCallToAction &&
                                <li>
                                    <AppDownloadButton
                                        content={ iOsAppStoreDownload }
                                    />
                                    <AndroidDownloadButton
                                        content={ aOsAppStoreDownload }
                                    />
                                </li>
                            }
                            {
                                callToActionExternalLink && 
                                <li>
                                    <span 
                                        className={ cmptStyles.callToAction }
                                    >
                                        <AutoLink uri={ callToActionExternalLink }>
                                            { callToActionLabel }
                                        </AutoLink>
                                    </span>
                                </li>
                            }
                            {
                                !callToActionExternalLink && callToActionTargetPage &&
                                <li>
                                    <span 
                                        className={ cmptStyles.callToAction }
                                    >
                                        <AutoLink uri={ callToActionTargetPage.route }>
                                            { callToActionLabel }
                                        </AutoLink>
                                    </span>
                                </li>
                            }
                            </ul>
                        }

                    </div>

                    <div className={ cmptStyles.image }>
                        <figure>
                            <ContentfulImage image={ image } />
                        </figure>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Feature

export const featureQuery = graphql`
    fragment Feature on ContentfulModuleFeature {
        id
        internal {
            type
        }
        callToActionExternalLink
        callToActionLabel
        callToActionTargetPage {
            route
        }
        description {
            description
        }
        image {
            ...ContentfulImageProperties
        }
        includeAppDownloadCallToAction
        title
    }
`