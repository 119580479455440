import React from "react"
import cmptStyles from "../../styles/shared/filter.module.css"
import { useStaticQuery, graphql } from "gatsby"

const Filter = ({ 
    activeFilter,
    activeGroup,
    filterAlignment,
    toggleFilter
}) => {
    const data = useStaticQuery(
        graphql`
            query {
                dataJson {
                    ...Filters
                }
            }
        `
    )

    const labels = data.dataJson.filters.labels;
    const group = data.dataJson.filters.groups[activeGroup];
    const cmptAlignment = ( filterAlignment === 'right' ) ? cmptStyles.rightAligned : cmptStyles.centerAligned;

    return (
        <div className={ [ cmptStyles.filter, cmptAlignment ].join(' ') }>
            <ul className={ cmptStyles.list }>
            { group.map( item => {

                const activeItem = ( item === activeFilter ) ? true : false;
                const label = labels[item];

                return (
                    <li className={ cmptStyles.item }>
                        <button
                            className={ ( activeItem ) ? cmptStyles.active : cmptStyles.inactive }
                            onClick={ () => toggleFilter(item) }
                        >
                            { label }
                        </button>
                    </li>
                )

            }) }
            </ul>
        </div>
    )
}

export default Filter

export const filtersQuery = graphql`
    fragment Filters on DataJson {
        filters {
            groups {
                articles
                certifications
                experiences
                team
            }
            labels {
                advisors
                ambassadors
                breathing
                experience
                fitness
                founders
                level1
                level2
                level3
                lifestyle
                masterCoach
                nutrition
                recovery
                team
                viewAll
                workshop
            }
        }
    }
`