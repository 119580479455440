import React from "react"
import cmptStyles from "../../styles/shared/button.module.css"
import { AutoLink } from "../shared/link"

export const AppDownloadButton = ({ 
    content: { 
        link, 
        image: { 
            altText, 
            file 
        } 
    } 
}) => {
    return (
        <span 
            title={ altText } 
            className={ cmptStyles.appDownload }
        >
            <AutoLink uri={ link }>
                <img
                    src={ file }
                    alt={ altText }
                />
            </AutoLink>
        </span>
    )
}

export const AndroidDownloadButton = ({ 
    content: { 
        link, 
        image: { 
            altText, 
            file 
        } 
    } 
}) => {
    return (
        <span 
            title={ altText } 
            className={ cmptStyles.appDownload }
        >
            <AutoLink uri={ link }>
                <img
                    src={ file }
                    alt={ altText }
                />
            </AutoLink>
        </span>
    )
}

export const FilledButton = ({ route, label }) => {
    return (
        <span 
            className={ cmptStyles.filled }
        >
            <AutoLink uri={ route }>
                <span>
                    { label }
                </span>
            </AutoLink>
        </span>
    )
}

export const OutlineButton = ({ route, label }) => {
    return (
        <span 
            className={ cmptStyles.outline }
        >
            <AutoLink uri={ route }>
                <span>
                    { label }
                </span>
            </AutoLink>
        </span>
    )
}