import React from "react"
import cmptStyles from "../../styles/modules/highlights.module.css"
import { graphql } from "gatsby"
import Icon from "../shared/icon"

const Highlight = ({ 
    description, 
    icon,
    title
}) => {
    return (
        <li className={ cmptStyles.item }>
            {
                icon &&
                <span className={ cmptStyles.icon }>
                    <Icon theme="dark" name={ icon } />
                </span>
            }
            <span className={ cmptStyles.title }>{ title }</span>
            <p className={ cmptStyles.description }>{ description }</p>
        </li>
    )
}

const Highlights = ({ 
    content: { 
        highlight1Description,
        highlight1Icon,
        highlight1Title,
        highlight2Description,
        highlight2Icon,
        highlight2Title,
        highlight3Description,
        highlight3Icon,
        highlight3Title,
        highlight4Description,
        highlight4Icon, 
        highlight4Title
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="highlights">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>

                    <ul className={ cmptStyles.items }>
                        <Highlight
                            title={ highlight1Title }
                            description={ highlight1Description }
                            icon={ highlight1Icon }
                        />
                        {
                            highlight2Title &&
                            <Highlight
                                title={ highlight2Title }
                                description={ highlight2Description }
                                icon={ highlight2Icon }
                            />
                        }
                        {
                            highlight3Title &&
                            <Highlight
                                title={ highlight3Title }
                                description={ highlight3Description }
                                icon={ highlight3Icon }
                            />
                        }
                        {
                            highlight4Title &&
                            <Highlight
                                title={ highlight4Title }
                                description={ highlight4Description }
                                icon={ highlight4Icon }
                            />
                        }
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default Highlights

export const highlightsQuery = graphql`
    fragment Highlights on ContentfulModuleHighlights {
        id
        internal {
            type
        }
        highlight1Description
        highlight1Icon
        highlight1Title
        highlight2Description
        highlight2Icon
        highlight2Title
        highlight3Description
        highlight3Icon
        highlight3Title
        highlight4Description
        highlight4Icon
        highlight4Title
    }
`