import React from "react"
import cmptStyles from "../../styles/modules/textTwoColumn.module.css"
import { graphql } from "gatsby"
import RichText from "../shared/richtext"

const TextTwoColumn = ({ 
    content: { 
        leftColumnText,
        rightColumnText 
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="twoColumn">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <div className={ cmptStyles.text }>
                        <div className={ cmptStyles.leftText }>
                            <RichText content={ leftColumnText }/>
                        </div>
                    
                        <div className={ cmptStyles.rightText }>
                            <RichText content={ rightColumnText }/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default TextTwoColumn

export const textTwoColumnQuery = graphql`
    fragment TextTwoColumn on ContentfulModuleTextTwoColumn {
        id
        internal {
            type
        }
        leftColumnText {
            json
        }
        rightColumnText {
            json
        }
    }
`