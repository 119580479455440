import React from "react"
import cmptStyles from "../../styles/modules/heroAsset.module.css"
import { graphql } from "gatsby"
import { FilledButton } from "../shared/button"
import { ContentfulImage } from "../shared/image"

const HeroAsset = ({ 
    content: { 
        customCallToActionExternalLink,
        customCallToActionLabel, 
        customCallToActionTargetPage, 
        description: {
            description
        }, 
        image, 
        title
    } 
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="asset">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <div className={ cmptStyles.text }>
                        <h1 className={ cmptStyles.title }>{ title }</h1>
                        <p className={ cmptStyles.description }>{ description }</p>
                        
                        {
                            ( customCallToActionExternalLink || customCallToActionTargetPage ) &&
                            <ul className={ cmptStyles.links }>
                                {
                                    customCallToActionExternalLink && 
                                    <li>
                                        <FilledButton
                                            route={ customCallToActionExternalLink }
                                            label={ customCallToActionLabel }
                                        />
                                    </li>
                                }
                                {
                                    !customCallToActionExternalLink && customCallToActionTargetPage &&
                                    <li>
                                        <FilledButton
                                            route={ customCallToActionTargetPage.route }
                                            label={ customCallToActionLabel }
                                        />
                                    </li>
                                }
                            </ul>
                        }
                    </div>

                    <div className={ cmptStyles.image }>
                        <figure>
                            <ContentfulImage image={ image } />
                        </figure>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default HeroAsset

export const heroAssetQuery = graphql`
    fragment HeroAsset on ContentfulModuleHeroAsset {
        id
        internal {
            type
        }
        customCallToActionExternalLink
        customCallToActionLabel
        customCallToActionTargetPage {
            route
        }
        description {
            description
        }
        image {
            ...ContentfulImageProperties
        }
        title
    }
`