import React from "react"
import cmptStyles from "../../styles/modules/callToAction.module.css"
import { graphql } from "gatsby"
import { FilledButton } from "../shared/button"

const CallToAction = ({ 
    content: { 
        callToActionExternalLink,
        callToActionLabel, 
        callToActionTargetPage
    } 
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="callToAction">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <ul className={ cmptStyles.links }>
                    {
                        callToActionExternalLink && 
                        <li className={ cmptStyles.link }>
                            <FilledButton
                                route={ callToActionExternalLink }
                                label={ callToActionLabel }
                            />
                        </li>
                    }
                    {
                        !callToActionExternalLink && callToActionTargetPage &&
                        <li className={ cmptStyles.link }>
                            <FilledButton
                                route={ callToActionTargetPage.route }
                                label={ callToActionLabel }
                            />
                        </li>
                    }
                    </ul>

                </div>
            </div>
        </section>
    )
}

export default CallToAction

export const callToActionQuery = graphql`
    fragment CallToAction on ContentfulModuleCallToAction {
        id
        internal {
            type
        }
        callToActionExternalLink
        callToActionLabel
        callToActionTargetPage {
            route
        }
    }
`