import React from "react"
import cmptStyles from "../styles/modular.module.css"
import { graphql } from "gatsby"

import CallToAction from "./modules/callToAction"
import Comparison from "./modules/comparison"
import Feature from "./modules/feature"
import FeaturedFourUp from "./modules/featuredFourUp"
import FeaturedThreeUp from "./modules/featuredThreeUp"
import Feed from "./modules/feed"
import HeroAsset from "./modules/heroAsset"
import HeroFullscreen from "./modules/heroFullscreen"
import HeroOverlay from "./modules/heroOverlay"
import Highlights from "./modules/highlights"
import Insight from "./modules/insight"
import Introduction from "./modules/introduction"
import Team from "./modules/team"
import TeaserApp from "./modules/teaserApp"
import TeaserPage from "./modules/teaserPage"
import TextOneColumn from "./modules/textOneColumn"
import TextTwoColumn from "./modules/textTwoColumn"

const Modular = ({ content, siteFurniture, isAmp }) => {
    return (
        <div className={ cmptStyles.modulesContainer }>
            
            { content.modules && content.modules.map( module => {

                let friendlyModuleName = module.internal.type.replace('Contentful', '');

                switch(friendlyModuleName) {

                    case 'ModuleCallToAction':
                        return (<CallToAction content={ module } key={ module.id }/>);
                    case 'ModuleComparison':
                        return (<Comparison content={ module } key={ module.id } isAmp={ isAmp } />);
                    case 'ModuleFeature':
                        return (<Feature content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleFeaturedFourUp':
                        return (<FeaturedFourUp content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleFeaturedThreeUp':
                        return (<FeaturedThreeUp content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleFeed':
                        return (<Feed content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleHeroAsset':
                        return (<HeroAsset content={ module } key={ module.id }/>);
                    case 'ModuleHeroFullscreen':
                        return (<HeroFullscreen content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleHeroOverlay':
                        return (<HeroOverlay content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleHighlights':
                        return (<Highlights content={ module } key={ module.id }/>);
                    case 'ModuleInsight':
                        return (<Insight content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleIntroduction':
                        return (<Introduction content={ module } key={ module.id }/>);
                    case 'ModuleTeam':
                        return (<Team content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleTeaserApp':
                        return (<TeaserApp content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleTeaserPage':
                        return (<TeaserPage content={ module } key={ module.id } siteFurniture={ siteFurniture }/>);
                    case 'ModuleTextOneColumn':
                        return (<TextOneColumn content={ module } key={ module.id }/>);
                    case 'ModuleTextTwoColumn':
                        return (<TextTwoColumn content={ module } key={ module.id }/>);

                    default:
                        return (<h2>Page module not recognized: { module.internal.type }</h2>)
                }

            }) }

        </div>
    )
}

export default Modular

export const ModularQuery = graphql`
    fragment Modular on ContentfulPageModular {
        id
        node_locale
        route
        modules {
            ... on ContentfulModuleCallToAction {
                ...CallToAction
            }
            ... on ContentfulModuleComparison {
                ...Comparison
            }
            ... on ContentfulModuleFeature {
                ...Feature
            }
            ... on ContentfulModuleFeaturedFourUp {
                ...FeaturedFourUp
            }
            ... on ContentfulModuleFeaturedThreeUp {
                ...FeaturedThreeUp
            }
            ... on ContentfulModuleFeed {
                ...Feed
            }
            ... on ContentfulModuleHeroAsset {
                ...HeroAsset
            }
            ... on ContentfulModuleHeroFullscreen {
                ...HeroFullscreen
            }
            ... on ContentfulModuleHeroOverlay {
                ...HeroOverlay
            }
            ... on ContentfulModuleHighlights {
                ...Highlights
            }
            ... on ContentfulModuleInsight {
                ...Insight
            }
            ... on ContentfulModuleIntroduction {
                ...Introduction
            }
            ... on ContentfulModuleTeam {
                ...Team
            }
            ... on ContentfulModuleTeaserApp {
                ...TeaserApp
            }
            ... on ContentfulModuleTeaserPage {
                ...TeaserPage
            }
            ... on ContentfulModuleTextOneColumn {
                ...TextOneColumn
            }
            ... on ContentfulModuleTextTwoColumn {
                ...TextTwoColumn
            }
        }
        seoTitle
        seoDescription {
            seoDescription
        }
        seoImage {
            fixed(quality: 80, width: 1920, resizingBehavior: SCALE) {
                src
            }
            description
        }
    }
`