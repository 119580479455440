import React from "react"
import cmptStyles from "../../styles/modules/heroFullscreen.module.css"
import { graphql } from "gatsby"
import { AndroidDownloadButton, AppDownloadButton, OutlineButton } from "../shared/button"
import { ContentfulImage } from "../shared/image"

const HeroFullscreen = ({
    content: { 
        customCallToActionExternalLink,
        customCallToActionLabel, 
        customCallToActionTargetPage, 
        description: {
            description
        }, 
        image, 
        includeAppDownloadCallToAction,
        includeBreatheMoveRecoverHeading, 
        title
    }, 
    siteFurniture: { 
        breatheText, 
        moveText, 
        recoverText, 
        iOsAppStoreDownload,
        aOsAppStoreDownload
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="fullscreen">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>

                    <div className={ cmptStyles.image }>
                        <figure>
                            <ContentfulImage image={ image } />
                        </figure>
                    </div>
                
                    <div className={ cmptStyles.text }>
                        <h1 className={ cmptStyles.title }>
                            {
                                includeBreatheMoveRecoverHeading &&
                                <span className={ cmptStyles.curatedHeading }>
                                    { breatheText }
                                    <span className={ cmptStyles.breathe }>.</span><br/>
                                    { moveText }
                                    <span className={ cmptStyles.move }>.</span><br/>
                                    { recoverText }
                                    <span className={ cmptStyles.recover }>.</span><br/>
                                </span>
                            }
                            <span>{ title }</span>
                        </h1>
                        <p className={ cmptStyles.description }>{ description }</p>
                        
                        {
                            ( includeAppDownloadCallToAction || customCallToActionExternalLink || customCallToActionTargetPage ) &&
                            <ul className={ cmptStyles.links }>
                                {
                                    includeAppDownloadCallToAction &&
                                    <li>
                                        <AppDownloadButton
                                            content={ iOsAppStoreDownload }
                                        />
                                        <AndroidDownloadButton
                                            content={ aOsAppStoreDownload }
                                        />
                                    </li>
                                }
                                {
                                    customCallToActionExternalLink && 
                                    <li>
                                        <OutlineButton
                                            route={ customCallToActionExternalLink }
                                            label={ customCallToActionLabel }
                                        />
                                    </li>
                                }
                                {
                                    !customCallToActionExternalLink && customCallToActionTargetPage &&
                                    <li>
                                        <OutlineButton
                                            route={ customCallToActionTargetPage.route }
                                            label={ customCallToActionLabel }
                                        />
                                    </li>
                                }
                            </ul>
                        }
                    </div>

                </div>
            </div>
        </section>
    )
}

export default HeroFullscreen

export const heroFullscreenQuery = graphql`
    fragment HeroFullscreen on ContentfulModuleHeroFullscreen {
        id
        internal {
            type
        }
        customCallToActionExternalLink
        customCallToActionLabel
        customCallToActionTargetPage {
            route
        }
        description {
            description
        }
        includeAppDownloadCallToAction
        includeBreatheMoveRecoverHeading
        image {
            ...ContentfulImageProperties
        }
        title
    }
`