import React from "react"
import cmptStyles from "../../styles/modules/featuredThreeUp.module.css"
import { graphql } from "gatsby"
import { ContentfulImage } from "../shared/image"
import { AutoLink } from "../shared/link"
import Icon from "../shared/icon"

const OneUp = ({ 
    icon, 
    image, 
    label, 
    route, 
    textLine1, 
    textLine2
}) => (
    <li className={ cmptStyles.item }>
        <AutoLink uri={ route }>
            <div className={ cmptStyles.itemImage }>
                <figure>
                    <ContentfulImage image={ image } />
                </figure>
            </div>
            <div className={ cmptStyles.itemContent }>
                <span className={ cmptStyles.itemText }>
                    {
                        icon &&
                        <span className={ cmptStyles.itemIcon }>
                            <Icon theme="light" name={ icon } />
                        </span>
                    }
                    { textLine1 }<br/>
                    { textLine2 }
                </span>
                <span className={ cmptStyles.itemLabel }>
                    { label }
                </span>
            </div>
        </AutoLink>
    </li>
)

const FeaturedThreeUp = ({ 
    content: { 
        displayXptLogo,
        section1Icon, 
        section1Image, 
        section1TargetPage, 
        section1TextLine1, 
        section1TextLine2, 
        section2Icon, 
        section2Image, 
        section2TargetPage, 
        section2TextLine1, 
        section2TextLine2, 
        section3Icon, 
        section3Image, 
        section3TargetPage, 
        section3TextLine1, 
        section3TextLine2
    }, 
    siteFurniture: { 
        logoXWithoutText,
        threeUpLabelText
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="threeUp">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>
                
                    <div className={ cmptStyles.items }>
                        <ul>
                            <OneUp
                                textLine1={ section1TextLine1 }
                                textLine2={ section1TextLine2 }
                                image={ section1Image }
                                label={ threeUpLabelText }
                                route={ section1TargetPage.route }
                                icon={ section1Icon }
                            />
                            {
                                section2TextLine1 &&
                                <OneUp
                                    textLine1={ section2TextLine1 }
                                    textLine2={ section2TextLine2 }
                                    image={ section2Image }
                                    label={ threeUpLabelText }
                                    route={ section2TargetPage.route }
                                    icon={ section2Icon }
                                />
                            }
                            {
                                section3TextLine1 &&
                                <OneUp
                                    textLine1={ section3TextLine1 }
                                    textLine2={ section3TextLine2 }
                                    image={ section3Image }
                                    label={ threeUpLabelText }
                                    route={ section3TargetPage.route }
                                    icon={ section3Icon }
                                />
                            }
                        </ul>
                    </div>

                </div>

                {
                    displayXptLogo &&
                    <div className={ cmptStyles.xptLogoWrapper }>
                        <div
                            className={ cmptStyles.xptLogo }
                            style={{
                                backgroundImage: `url(${ logoXWithoutText.file })`,
                            }}
                        />
                    </div>
                }

            </div>
        </section>
    )
}

export default FeaturedThreeUp

export const featuredThreeUpQuery = graphql`
    fragment FeaturedThreeUp on ContentfulModuleFeaturedThreeUp {
        id
        internal {
            type
        }
        displayXptLogo
        section1Icon
        section1Image {
            ...ContentfulImageProperties
        }
        section1TargetPage {
            route
        }
        section1TextLine1
        section1TextLine2
        section2Icon
        section2Image {
            ...ContentfulImageProperties
        }
        section2TargetPage {
            route
        }
        section2TextLine1
        section2TextLine2
        section3Icon
        section3Image {
            ...ContentfulImageProperties
        }
        section3TargetPage {
            route
        }
        section3TextLine1
        section3TextLine2
    }
`