import React from "react"
import cmptStyles from "../../styles/modules/teaserPage.module.css"
import { graphql } from "gatsby"
import { AutoLink } from "../shared/link"

const TeaserPage = ({ 
    content: { 
        callToActionExternalLink,
        callToActionLabel, 
        callToActionTargetPage,
        description: {
            description
        },
        includeBreatheMoveRecoverIcons, 
        title
    }, 
    siteFurniture: { 
        breatheLogoWithText, 
        moveLogoWithText, 
        recoverLogoWithText
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="page">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>

                    <div className={ cmptStyles.text }>
                        <h2 className={ cmptStyles.title }>{ title }</h2>
                        <p className={ cmptStyles.description }>{ description }</p>
                        {
                            ( callToActionExternalLink || callToActionTargetPage ) &&
                            <span className={ cmptStyles.link }>
                            {
                                callToActionExternalLink && 
                                <AutoLink uri={ callToActionExternalLink }>
                                    { callToActionLabel }
                                </AutoLink>
                            }
                            {
                                !callToActionExternalLink && callToActionTargetPage &&
                                <AutoLink uri={ callToActionTargetPage.route }>
                                    { callToActionLabel }
                                </AutoLink>
                            }
                            </span>
                        }
                    </div>
                    
                    {
                        includeBreatheMoveRecoverIcons &&
                        <div
                            className={ cmptStyles.icons }
                            style={{
                                backgroundImage: `url(${ breatheLogoWithText.file }), url(${ moveLogoWithText.file }), url(${ recoverLogoWithText.file })`,
                            }}
                        />
                    }

                </div>
            </div>
        </section>
    )
}

export default TeaserPage

export const teaserPageQuery = graphql`
    fragment TeaserPage on ContentfulModuleTeaserPage {
        id
        internal {
            type
        }
        callToActionExternalLink
        callToActionLabel
        callToActionTargetPage {
            route
        }
        description {
            description
        }
        includeBreatheMoveRecoverIcons
        title
    }
`